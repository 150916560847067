body {
    font-family: 'Arial', sans-serif;
    background-color: white;
    padding: 20px;
}

.Demo__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 30px;
    text-align: center;
}

.Demo__some-network__share-count {
    margin-top: 3px;
    font-size: 12px;
}

.Demo__some-network__share-button {
        cursor: pointer;
}

.Demo__some-network__share-button:hover:not(:active) {
    opacity: 0.75;
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
.tooltip .tooltiptext {
visibility: hidden;
width: 140px;
background-color: #555;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px;
position: absolute;
z-index: 1;
bottom: 150%;
left: 50%;
margin-left: -75px;
opacity: 0;
transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
visibility: visible;
opacity: 1;
}