.section-plan {
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
  }
  
  .section-plan .card {
    border: 1px solid #333333;
  }
  
  .section-plan .card .card-top {
    color: white;
    padding: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background-color: #8c52ff;
  }
  
  .plan-content {
    padding-top: 10px;
    text-align: center;
    padding-bottom: 15px;
  }
  
  .plan-content .btn-primary {
    margin-bottom: 20px;
    background-color: #8c52ff;
    color: white;
    border: 1px solid #8c52ff;
  }
  
  .plan-content .btn-primary:hover {
    background-color: #905afd;
  }
  .nav-list {
    text-align: initial;
    display: block;
    list-style-type: none;
  }
  .plan-content li, .nav-list li{
    line-height: 1.6 !important;
    margin: 0;
        padding: 0;
  }
  .nav-list1 li{
      line-height: 2.3 !important;
  }
  .d-flex {
    display: flex!important;
}
@media (max-width: 780px) {
    .container{
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media (min-width: 780px) {
    .container{
        padding-left: 180px;
        padding-right: 180px;
    }
}
.card{
    border-radius: 10px;
}

li img{
    transform: translateY(10px);
}
